import React, { useEffect, useState, KeyboardEvent } from 'react';
import { IAppState } from 'types/appState.type';
import { globalPaths } from 'utils/dataClient';
import { useTranslation } from 'react-i18next';
import { ContactRailConfig } from 'data/ContactRailConfig';
import { LivePersonConfig } from 'data/LivePersonConfig'
import { String } from 'typescript-string-operations';
import styles from './ContactRightRail.module.scss';

import { ContactRailSetting } from '../../types/schema.type';
import ProactiveChatModalComponent from './ProactiveChatModalComponent';

export interface IContactRailProps {
    setShowCallToAction(showCallToAction: boolean): void;
    setShowProactiveChat(showProactiveChat: boolean): void;
    selectedCollection: any;
}

export interface IContactRailStateWithDispatch extends IAppState, IContactRailProps { }

export const ContactRightRailComponent = (props: IContactRailStateWithDispatch) => {
    const DefaultSiteLocale = 'en-us';
    const { t } = useTranslation();
    const { match, projectState, localeState, cookieBannerState } = props;
    const { isRTLCulture } = localeState;
    const { project, showProactiveChat, showCallToAction } = projectState;
    const { selectedCollection } = props;  // Destructure selectedCollection from props
    const selectedLocale = localeState.selectedLocale
        ? localeState.selectedLocale
        : match.params.selectedLocale
            ? match.params.selectedLocale.toLowerCase()
            : DefaultSiteLocale;

    const iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();
    const chatButtonContainerRef: React.RefObject<HTMLLIElement> = React.createRef();
    let lpIsInitialized = false;
    let lpConsentRequired = true;
    if (window.siteConsent) {
        lpConsentRequired = window.siteConsent.isConsentRequired;
    }

    let isContactRailLocale = false;

    const settingsFiltered = ContactRailConfig.filter(
        (setting: ContactRailSetting) =>
            setting.Slug === selectedLocale
    );
    isContactRailLocale = settingsFiltered.length > 0 ? true : false;

    const setting = settingsFiltered[0];

    useEffect(() => {
        if (isContactRailLocale && !project.livePersonMitigationEnabled) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `${globalPaths.baseUrl}/assets/liveperson-legacy.js`;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isContactRailLocale]);

    const [isExpanded, setIsExpanded]: [boolean, React.Dispatch<boolean>] =
        useState<boolean>(false);

    const toggleWidget = React.useCallback(
        () => setIsExpanded(!isExpanded),
        [isExpanded, setIsExpanded]
    );


    // Method for sending messages to the iframe
    const sendMessage = (msg: any) => {

        if (iframeRef.current === null)
            return;

        if (iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(msg, LivePersonConfig.livePersonDomain);
        }
    }

    const enableChatButton = (chatButtonRef: HTMLLIElement | null) => {

        if (!chatButtonRef)
            return;

        chatButtonRef.style.display = "flex";

        if (lpConsentRequired === false && lpIsInitialized === false) {
            sendMessage({ action: 'initializeLP' })
            lpIsInitialized = true;
        }
        sendMessage({ lppagename: document.title });
        sendMessage({ lpcurl: window.location.href });
    }

    const triggerChatModal = () => {

        //Only allow trigger once, and not if tourEnd modal showing.
        if (!showCallToAction && !showProactiveChat) {
            props.setShowCallToAction(false);
            props.setShowProactiveChat(true);
        }
    };

    const lpResizeListenerSetup = (el: UIEvent) => {

        if (!el.target)
            return;

        // We utilize this to provide updates to the iFrame so the window can be resized if needed.  An example of this would be the user reducing the height of the browser below the height of the chat window.
        sendMessage({ action: 'parentsize', Height: window.innerHeight, PixelRatio: 1, Width: window.innerWidth });
    }

    const lpMessageListenerSetup = React.useCallback((event: MessageEvent) => {

        if (event.origin === LivePersonConfig.livePersonDomain && event && event.data && iframeRef.current) {
            var src = event.data.action || event.data.window || event.data.engagement || event.data.button || event.data.invite;
            var iframe = iframeRef.current;
            var chatButtonRef = chatButtonContainerRef.current ||  null;

            switch (src) {

                case 'ready':
                    {
                        sendMessage({ action: 'parentsize', Height: window.innerHeight, PixelRatio: 1, Width: window.innerWidth }); // This tells the iframe the available dimensions for the chat window.  This is especially important for mobile deployments.
                        break;
                    }

                case 'maximized': // When maximized is passed from the iFrame we expect it to contain window dimensions, if it's mobile we'll be setting vh values, otherwise we're setting px values.  Both need to be assigned to the iFrame container.
                    {
                        iframe.style.display = 'flex'
                        iframe.style.width = event.data.width;
                        iframe.style.height = event.data.height;

                        if (event.data.height === '100vh') { // Mobile styling, detected by vh being passed vs px
                            iframe.style.position = 'absolute';
                            iframe.style.right = '0';
                        } else { // Desktop styling
                            iframe.style.position = 'fixed';
                            iframe.style.bottom = '0';
                            iframe.style.right = '0';
                            iframe.style.boxShadow = '0 0 6px rgb(214, 214, 214)';
                        }
                        break;
                    }

                case 'minimized': // When minimized is passed the iframe should be hidden, it is important that your team add a button to restore the minimized chat like store currently does after this command is passed.
                    {
                        iframe.style.width = event.data.width;
                        iframe.style.height = event.data.height;

                        iframe.style.position = 'fixed';
                        iframe.style.bottom = '0';
                        iframe.style.right = '0';
                        iframe.style.boxShadow = '0 0 6px rgb(214, 214, 214)';
                        break;
                    }

                case 'closed': // This command is passed when the chat is closed, you do not need to add a restore button and you should recieve a
                    {
                        iframe.style.display = 'none'
                        iframe.style.width = event.data.width;
                        iframe.style.height = event.data.height;
                        break;
                    }

                case 'opened': // This command is lets you know the window is opened, this usually occurs as the user navigates between pages while a chat is occuring.
                    {
                        iframe.style.display = 'flex'
                        iframe.style.width = event.data.width;
                        iframe.style.height = event.data.height;
                        break;
                    }

                case 'buttonReady': // this lets us know that a button is on the page and ready to be clicked.
                    {
                        enableChatButton(chatButtonRef);
                        break;
                    }

                case 'buttonNotReady':
                    {
                        if (!chatButtonContainerRef.current)
                            return;
                        chatButtonContainerRef.current.style.display = "none";
                        break;
                    }

                case 'displayed': // This command should trigger your pages invite (ie Proactive Chat)
                    {
                        triggerChatModal();
                        break;
                    }

                default:
                    break;
            }
        }

    }, [iframeRef, chatButtonContainerRef]);

    //Setup and remove listeners on component creation and deletion.
    useEffect(() => {
        if (project.livePersonMitigationEnabled) {

            window.addEventListener('message', lpMessageListenerSetup);
            window.addEventListener('resize', lpResizeListenerSetup);
        }

        return () => {
            if (project.livePersonMitigationEnabled) {
                window.removeEventListener('message', lpMessageListenerSetup);
                window.removeEventListener('resize', lpResizeListenerSetup);
            }
        };

    }, [lpMessageListenerSetup]);

    const getContactUrl = (collectionId: string) => {
        switch (collectionId.toLowerCase()) {  // Ensures case-insensitive matching
            case 'dynamics':
                return 'https://go.microsoft.com/fwlink/?LinkId=861635';
            case 'power-platform':  // Update this to match the actual collection ID
                return 'https://info.microsoft.com/ww-landing-microsoft-power-platform.html';
            default:
                return 'https://go.microsoft.com/fwlink/?LinkId=861635'; // Default URL
        }
    };     
    
    // Use the selectedCollection from props instead of projectState
    const contactUrl = getContactUrl(selectedCollection?.id || '');    

    //Ensure we only setup the iFrame source the one time.
    const setupIFrame = () => {

        if (iframeRef.current) {
            let currentSrc = iframeRef.current.getAttribute('src');
            if (!currentSrc || currentSrc === '') { //Ensure we only set the source once.
                var LpButtons = "lpChatDynamics,lpChatDynamics2";
                let lpUrl = String.Format(LivePersonConfig.livePersonPath, LivePersonConfig.livePersonDomain, LivePersonConfig.livePersonSiteID, setting.ChatLeadgen, LpButtons);
                iframeRef.current.setAttribute('src', lpUrl.replace(/&amp;/g, '&'));
            }
        }
    };

    //Ensure we keep retrying to set the iFrame source while the reference isn't set.
    const iFrameRetry = React.useCallback(() => {
        if (iframeRef.current) {
            setupIFrame();
        }
        else {
            setTimeout(() => {
                iFrameRetry();
            }, 500);
        }
    }, [iframeRef]);

    if (project.livePersonMitigationEnabled) {
        // Loading the iFrame later so our code is ready when LivePerson accesses it.            
        setTimeout(() => {
            iFrameRetry();
        }, 500);
    }

    const chatButtonOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        console.log("chat clicked");
        event.preventDefault();
        sendMessage({ action: 'open' });
        sendMessage({ action: 'Topic', Topic: 'Dynamics' });
        sendMessage({ action: 'parentsize', Height: window.innerHeight, PixelRatio: 1, Width: window.innerWidth });
    }

    const chatNowOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) => {
        if (!event)
            return;

        event.preventDefault();

        props.setShowProactiveChat(false);

        if (!lpIsInitialized) {
            sendMessage({ action: 'initializeLP' });
        }

        sendMessage({ invite: 'accepted' });
        sendMessage({ action: 'Topic', Topic: 'Dynamics' });
        sendMessage({ action: 'parentsize', Height: window.innerHeight, PixelRatio: 1, Width: window.innerWidth });
    }

    const cancelOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) => {
        if (event) {
            event.preventDefault();
        }

        sendMessage({ invite: 'declined' });
        props.setShowProactiveChat(false);
    }



    const getMigitationChat = () => {

        if (!project.livePersonMitigationEnabled || !setting.IsCallModeEnabled)
            return;

        return (
            <li
                className={styles.contactWidgetChatItem}
                id="widget-chat-container"
                ref={chatButtonContainerRef}
            >
                <div
                    className={styles.contactWidgetIcon}
                    role="presentation"
                    aria-hidden="true"
                >
                    <img
                        className={styles.contactWidgetImg}
                        src={`${globalPaths.baseUrl}/assets/widget-chat.svg`}
                        alt={t(project.contactRail.chatAlt)}
                    />
                </div>
                <div
                    className={
                        isExpanded
                            ? styles.contactWidgetExpandText
                            : styles.contactWidgetText
                    }
                    id="lpChatDynamics"
                    >
                    <a
                        id="contact-us-chat-cta"
                        className={styles.contactWidgetLink}
                        href="#"
                        onClick={chatButtonOnClick}
                        aria-label={t(project.contactRail.chatNowAria)}
                        role="button"
                    >
                        <span>{t(project.contactRail.chatNow)}</span>
                    </a>
                </div>
            </li>
        );

    };

    const getMitigationChatiFrameDOM = () => {

        if (!project.livePersonMitigationEnabled || !setting.IsCallModeEnabled)
            return;

        return (
            <iframe tabIndex={-1}
                title={t(project.contactRail.iFrameChat)}
                className={styles.lp_iframe_chatwindow}
                name="lp_iframe_chatwindow"
                id="lp_iframe_chatwindow"
                allow="camera;microphone"
                ref={iframeRef}
            >
            </iframe>
        );
    }

    const getLegacyChat = () => {

        //Only allow legacy if all three conditions true (not using Mitigation, CallMode is Enabled and Advertising consent).
        if (!setting.IsCallModeEnabled || project.livePersonMitigationEnabled || !cookieBannerState.consentValues.Advertising)
            return;

        return (
            <li
                className={styles.contactWidgetChatItem}
                id="widget-chat-container"
            >
                <div
                    className={styles.contactWidgetIcon}
                    role="presentation"
                    aria-hidden="true"
                >
                    <img
                        className={styles.contactWidgetImg}
                        src={`${globalPaths.baseUrl}/assets/widget-chat.svg`}
                        alt={t(project.contactRail.chatAlt)}
                    />
                </div>
                <div
                    className={
                        isExpanded
                            ? styles.contactWidgetExpandText
                            : styles.contactWidgetText
                    }
                    id="lpChatDynamics"
                    aria-label={t(project.contactRail.chatAriaLabel)}
                ></div>
            </li>
        );

    };

    if (!isContactRailLocale) {
        return null;
    } else {
        return (
            <div dir={isRTLCulture ? 'rtl' : 'ltr'}>
                <div
                    className={styles.contactWidget}
                    data-expanded={isExpanded}
                    onClick={toggleWidget}
                >
                    <button
                        className={
                            isExpanded
                                ? styles.contactWidgetButtonExpand
                                : styles.contactWidgetButton
                        }
                        aria-expanded={isExpanded}
                        aria-label={
                            isExpanded
                                ? t(project.contactRail.widgetCollapseAriaLabel)
                                : t(project.contactRail.widgetExpandAriaLabel)
                        }
                    ></button>

                    <ul className={styles.contactWidgetList}>

                        { /* Return appropriate chat based on setting */}
                        {getMigitationChat()}
                        {getLegacyChat()}

                        {setting.IsRequestModeEnabled && (
                            <li className={styles.contactWidgetListItem}>
                                <div
                                    className={styles.contactWidgetIcon}
                                    role="presentation"
                                    aria-hidden="true"
                                >
                                    <img
                                        className={styles.contactWidgetImg}
                                        src={`${globalPaths.baseUrl}/assets/widget-contactus.svg`}
                                        alt={t(project.contactRail.contactUsAlt)}
                                    />
                                </div>
                                <div
                                    className={
                                        isExpanded
                                            ? styles.contactWidgetExpandText
                                            : styles.contactWidgetText
                                    }
                                >
                                    <a
                                        className={styles.contactWidgetLink}
                                        href={contactUrl}
                                        aria-label={t(project.contactRail.contactUsAriaLabel)}
                                    >
                                        {t(project.contactRail.contactUs)}
                                    </a>
                                </div>
                            </li>
                        )}

                        {setting.IsCallModeEnabled && (
                            <li className={styles.contactWidgetLastListItem}>
                                <div
                                    className={styles.contactWidgetLastIcon}
                                    role="presentation"
                                    aria-hidden="true"
                                >
                                    <img
                                        className={styles.contactWidgetImg}
                                        src={`${globalPaths.baseUrl}/assets/widget-call.svg`}
                                        alt={t(project.contactRail.callAlt)}
                                    />
                                </div>
                                <div
                                    className={
                                        isExpanded
                                            ? styles.contactWidgetExpandText
                                            : styles.contactWidgetText
                                    }
                                >
                                    <a
                                        className={styles.contactWidgetLink}
                                        href={'tel:' + setting.PhoneNo}
                                        aria-label={t(project.contactRail.callAriaLabel)}
                                    >
                                        <span>{setting.PhoneNo}</span>
                                    </a>
                                    <span className={styles.contactWidgetMiniText}>
                                        {t(project.contactRail.phoneAvailability)}
                                    </span>
                                </div>
                            </li>
                        )}
                    </ul>

                    <button
                        className={
                            isExpanded
                                ? styles.contactWidgetExpandClose
                                : styles.contactWidgetClose
                        }
                        aria-label={t(project.contactRail.closeAriaLabel)}
                    >
                        X {t(project.contactRail.close)}
                    </button>
                </div>
                <div>
                    {getMitigationChatiFrameDOM()}
                    {showProactiveChat && (
                        <ProactiveChatModalComponent
                            cancelOnClick={cancelOnClick}
                            chatNowOnClick={chatNowOnClick}
                            chatMarketImage={setting.ChatMarketImage}
                            needHelp={t(project.contactRail.needHelp)}
                            sectionText={t(project.contactRail.sectionText)}
                            chatModalAria={t(project.contactRail.chatModalAria)}
                            chatNow={t(project.contactRail.chatNow)}
                            chatNowAria={t(project.contactRail.chatNowAria)}
                            noThanks={t(project.contactRail.noThanks)}
                            noThanksAria={t(project.contactRail.noThanksAria)}
                            modalCloseButton={t(project.contactRail.modalCloseButton)}
                            isRTLCulture={isRTLCulture}
                        />)}
                </div>
            </div>

        );
    }
};
